<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #name>
      <div class="tariffs-table-item medium-text">
        {{ item.floating_ip_address }}
      </div>
      <div class="tariffs-table-item__specs note-text">
        {{ item.description }}
      </div>
    </template>
    <!--    <template #description>-->
    <!--      <div class="tariffs-table-item__ip">-->
    <!--        {{ item.description }}-->
    <!--      </div>-->
    <!--    </template>-->
    <template #instance>
      <router-link
        v-if="route && route === 'Balancer'"
        :to="{
          name: route,
          params: { balancerid: item.port_details.device_id.replace('lb-', '') },
        }"
        class="tariffs-table-item__link medium-text"
      >
        {{ toInstance(item) }}
      </router-link>
      <router-link
        v-else-if="route && route === 'Server'"
        :to="{ name: route, params: { serverid: item.port_details.device_id } }"
        class="tariffs-table-item__link medium-text"
      >
        {{ toInstance(item) }}
      </router-link>
      <!--      <div v-else class="tariffs-table-item__ip">-->
      <!--        {{ toInstance(item) }}-->
      <!--      </div>-->
      <div v-else-if="typeLink === 'text'" class="tariffs-table-item__ip">
        <!--        {{ attach(item) }}-->
      </div>
    </template>
    <template #port>
      <div class="tariffs-table-item__ip">
        {{ item.fixed_ip_address ? item.fixed_ip_address : '' }}
      </div>
    </template>
    <template #conditions>
      <div class="tariffs-table-item__ip">
        {{ item.admin_state_up }}
      </div>
    </template>
    <template #status>
      <main-label :color="item.status.color" theme="plain" class="tariffs-table-item__status">
        {{ $t(`status.${item.status.key}`) }}
      </main-label>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button icon="more" class="tariffs-table-item__more" @click="togglePopoverState" />
        <floating-ip-context-menu
          slot="popover"
          :tariff="item"
          class="tariff-table-item__context-menu"
        />
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import MainLabel from '@/components/Labels/MainLabel.vue';
import FloatingIpContextMenu from './FloatingIpContextMenu';
import popoverFix from '@/mixins/popoverFix';
export default {
  name: 'TariffsTableFloatingIpRow',
  components: {
    BaseTableRow,
    MainLabel,
    FloatingIpContextMenu,
  },
  mixins: [popoverFix],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      route: '',
      typeLink: '',
    };
  },
  computed: {
    servers() {
      return this.$store.state.moduleStack.servers;
    },
    balancers() {
      return this.$store.state.moduleStack.balancers;
    },
  },
  mounted() {
    this.toInstance(this.item);
  },
  methods: {
    printParam(param, payload) {
      return param.toString(payload);
    },
    isBootable(item) {
      return item.bootable === 'true' ? 'Загрузочный' : ' - ';
    },
    toInstance(item) {
      // console.log(item.port_details);
      if (!item.port_details) {
        this.typeLink = 'text';
        return '';
      }
      const type = item && item.port_details ? item.port_details.device_owner : null;
      let id = item && item.port_details ? item.port_details.device_id : null;
      // console.log(type, id);
      if (id && id.startsWith('lb')) id = id.slice(3);
      // console.log(this.balancers.find(i => i.id === id.slice(3)));
      if (id && type) {
        this.route = type === 'compute:nova' ? 'Server' : type === 'Octavia' ? 'Balancer' : '';
        const name =
          id && type === 'compute:nova'
            ? this.servers.find(i => i.id === id).name
            : id && type === 'Octavia'
            ? this.balancers.find(i => i.id === id).name
            : // ? this.balancers.find(i => i.id === id.slice(3)).name
              '---';
        // console.log(id);
        return item.fixed_ip_address ? name : '';
      } else return '';
    },
    viewSubnets(id) {
      return this.subnets.find(i => i.id === id).name;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "gb": "{num} ГБ"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: ellipsis;
      //text-overflow: unset;
      //overflow: visible;
      overflow: hidden;
      z-index: 2;
    }
  }
  &__sub{
    margin-top: 0.5rem;
  }
  &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    font-weight: 600;
    text-decoration: none;
    white-space: nowrap;
    background: var(--main-card-bg);
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      width: calc(100vw - 6rem);
    }
  }
}
</style>
