<template>
  <div class="stack-context-menu">
    <template v-if="menu.length && menu[0].length">
      <div v-for="(group, i) in menu" :key="i" class="stack-context-menu__group">
        <component
          :is="item.attrs ? 'a' : 'div'"
          v-for="item in group"
          :key="item.key"
          v-bind="item.attrs"
          class="stack-context-menu__item standart-title"
          :class="{ [`stack-context-menu__item--${item.color}`]: !!item.color }"
          :disabled="item.disabled"
          @click="onClick(item)"
        >
          {{ $t(`actions.${item.key}`) }}
        </component>
      </div>
    </template>
    <template v-else>
      <div class="stack-context-menu__noact standart-text">
        <div v-if="isSuspended" class="standart-title">
          {{ $t('noact.suspended') }}
        </div>
        <div>{{ $t('noact.noactions') }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import showErrorModal from '@/mixins/showErrorModal';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import attachPortToFloatIp from '@/mixins/attachPortToFloatIp';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
import changeFloatingIpDescription from '@/mixins/changeFloatingIpDescription';
export default {
  name: 'FloatingIpContextMenu',
  mixins: [attachPortToFloatIp, showErrorModal, changeFloatingIpDescription],
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      res: '',
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
      snapId: '',
      imageId: '',
    };
  },
  computed: {
    serverId() {
      return this.tariff.attachments.length > 0 ? this.tariff.attachments[0].server_id : '';
    },
    // dev() {
    //   return IS_PROD ? 'stat' : 'full_price';
    // },
    servers() {
      return this.$store.getters['moduleStack/servers'];
    },
    projectId() {
      return this.$store.getters['moduleStack/project_id'];
    },
    date() {
      return this.$d(Date.now(), 'short').replaceAll('.', '_');
    },
    time() {
      return this.$d(Date.now(), 'long').replace(/[^\d]/g, '_');
    },
    project() {
      return this.$store.getters['moduleStack/project_id'];
    },
    id() {
      return this.tariff.id;
    },
    processingStatus() {
      // return this.tariff.specialstatus.toNumber();
      return 2;
    },
    isSuspended() {
      return this.tariff.abusesuspend === 'on' || this.tariff.employeesuspend === 'on';
    },
    price() {
      return this.$store.state.moduleStack.price.length > 0
        ? this.$store.state.moduleStack.price.find(x => x.intname === 'floating_ip')[dev]
        : null;
    },
    cost() {
      return this.price ? this.price : 0;
    },
    menu() {
      const arr = [];
      this.tariff.fixed_ip_address
        ? arr.push([
            {
              key: 'descr',
              handler: 'descr',
            },
            {
              key: 'detach',
              id: this.id,
              handler: 'state',
              method: 'put',
              label: this.tariff.floating_ip_address,
              action: {
                admin_state_up: !this.tariff.admin_state_up,
              },
            },
            {
              key: 'delete',
              color: 'del',
              label: this.tariff.floating_ip_address,
              handler: 'delete',
              action: 'delete',
            },
          ])
        : arr.push([
            {
              key: 'descr',
              handler: 'descr',
            },
            {
              key: 'edit',
              id: this.id,
              handler: 'func',

              action: {
                network: {
                  name: this.tariff.name,
                },
              },
            },
            {
              key: 'delete',
              color: 'del',
              method: 'delete',
              label: this.tariff.floating_ip_address,
              handler: 'delete',
              action: 'delete',
            },
          ]);
      return arr;
    },
  },
  mounted() {
    // if (this.processingStatus === 1) this.updateStatus();
  },
  beforeDestroy() {
    if (this.timerId) clearTimeout(this.timerId);
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      if (item.action && item.handler === 'delete') this.askSure(item);
      if (item.action && item.key === 'detach') this.askSure(item);
      if (item.action && item.key === 'edit') this.attachPortToFloatIp(item.id);
      if (item.handler === 'descr') this.changeFloatingIpDescription(this.tariff);
    },
    dettachFloatingIpToPort(id, method = 'put') {
      // console.log(port, id, ip);
      const params = {
        port: null,
        id: id,
        ip: null,
        method: method,
      };
      return method === 'put'
        ? this.$store.dispatch('moduleStack/attachFloatingIpToPort', params).catch(e => {
            this.showError(e);
          })
        : this.$store.dispatch('moduleStack/dettachFloatingIpToPort', params).catch(e => {
            this.showError(e);
          });
    },
    deleteFloatingIpToPort(id) {
      const params = {
        port: null,
        id: id,
        ip: null,
      };
      return this.$store.dispatch('moduleStack/deleteFloatingIpToPort', params).catch(e => {
        this.showError(e);
      });
    },
    askSure(action) {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let mode = '';
      let time;
      // console.log(action.key);
      this.$modals.open({
        name: selfName,
        html: true,
        text:
          action.key === 'detach'
            ? this.$t('modal.sure.text', { msg: action.label })
            : this.$t('modal.sure.textDelete', {
                msg: action.label,
                cost: this.$n(this.cost, 'currency', this.$i18n.locale),
              }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
              color: action.key === 'detach' ? 'primary' : 'error',
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                if (action.key === 'detach') {
                  this.dettachFloatingIpToPort(this.tariff.id, 'put')
                    // this.deleteFloatingIpToPort(this.tariff.id)
                    .then(data => {
                      this.isProcessing = false;
                      mode = data;
                      res = 'success';
                    })
                    .catch(() => {
                      res = 'fail';
                    })
                    .finally(() => {
                      this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'floatingips');

                      const delay = Date.now() - time < 1000 ? 1000 : 0;
                      setTimeout(() => {
                        self.component = null;
                        self.closable = true;
                        self.text = this.$t(`modal.sure.${res}`);
                        self.footer = {
                          centered: true,
                          confirm: {
                            props: { title: this.$t('modal.sure.close') },
                            on: { click: () => this.$modals.close() },
                          },
                        };
                      }, delay);
                    });
                } else {
                  this.deleteFloatingIpToPort(this.tariff.id)
                    .then(data => {
                      this.isProcessing = false;
                      mode = data;
                      res = 'success';
                    })
                    .catch(() => {
                      res = 'fail';
                    })
                    .finally(() => {
                      this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'floatingips');
                      this.$store.dispatch('moduleStack/fetchQuotasNetwork', this.project);
                      const delay = Date.now() - time < 1000 ? 1000 : 0;
                      setTimeout(() => {
                        self.component = null;
                        self.closable = true;
                        self.text = this.$t(`modal.sure.${res}`);
                        self.footer = {
                          centered: true,
                          confirm: {
                            props: { title: this.$t('modal.sure.close') },
                            on: { click: () => this.$modals.close() },
                          },
                        };
                      }, delay);
                    });
                }
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "delete": "Вы уверены, что хотите удалить сеть:",
    "actions": {
      "edit": "Назначить",
      "detach": "Снять назначение",
      "delete": "Удалить плавающий IP",
      "descr": "Изменить описание"
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена",
      "title": "Увеличить размер диска"
    },
    "noact": {
      "noactions": "Нет доступных действий.",
      "suspended": "Услуга остановлена администратором."
    },
    "sure": {
      "confirm": "Подтвердить"
    },
    "modal": {
      "description": "Описание статического плавающего IP-адреса",
      "sure": {
        "text": "Вы собираетесь освободить <b>%{msg}</b> адрес. <br /> <br /> Вы уверены?",
        "textDelete": "Вы собираетесь удалить <b>%{msg}</b> адрес и связанный с ним порт. <br /><br /> При этом ваши расходы уменьшатся на <b>%{cost}</b> в час. <br /><br /> Вы уверены?",
        "confirm": "Да, уверен",
        "close": "Закрыть",
        "true": "включена",
        "false": "выключена",
        "success": "действие с IP выполнено успешно",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    }
  }
}
</i18n>
<!--//        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса",-->

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.stack-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }

  &__noact {
    div + div {
      margin-top: 0.75rem;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.stack-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
